import React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"

import Breadcrumb from "../components/breadcrumb"
import Paragraph from "../components/paragraph"
import PageTitle from "../components/page-title"
import Stack from "../components/stack"
import TeaserDigital from "../content/shared/teaser-digital"

const IndexPage = () => {
  return (
    <Layout backdrop="digital">
      <Seo
        title="Digitale Angebote"
        description="Als Ort der Vermittlung von Friedrich Hölderlins Leben und Werk öffnet sich der Hölderlinturm ins Netz – und damit neuen Vermittlungsformaten und -wegen. Unsere digitalen Angebote verstehen sich als inhaltliche Ergänzungen unserer Dauer- und Wechselausstellungen und stehen ganz unabhängig vom Museumsbesuch jederzeit zur Verfügung."
      />

      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
            ]}
          />
          <PageTitle>Hölderlinturm Digital</PageTitle>
          <Paragraph dropcap={true}>
            Gerade einmal 13 Quadratmeter umfasste Hölderlins Zimmer im Tübinger
            Turm, in dem der Dichter die zweite Hälfte seines Lebens verbrachte.
            Heute ist sein Wirkungsradius deutlich größer. Als Ort der
            Vermittlung von Friedrich Hölderlins Leben und Werk öffnet sich der
            Hölderlinturm ins Netz – und damit neuen Vermittlungsformaten und
            -wegen. Unsere digitalen Angebote verstehen sich als inhaltliche
            Ergänzungen unserer Dauer- und Wechselausstellungen und stehen ganz
            unabhängig vom Museumsbesuch jederzeit zur Verfügung. Ob auf dem
            Sofa, in der Bahn oder am Schreibtisch.
          </Paragraph>
        </Stack>
        <TeaserDigital />
      </Stack>
    </Layout>
  )
}

export default IndexPage
